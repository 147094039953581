import { extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";

const breakpoints = createBreakpoints({
  sm: "40em",
  md: "52em",
  lg: "64em",
  xl: "85em",
});

const theme = extendTheme({
  breakpoints,
  colors: {
    primary: "#2666FA",
    lightBlue: "#D1E6FF",
    landingPrimary: "#6B7280",
    lightBlue1: "#D1E7FC",
    lightBlue2: "#5887ED",
    black: "#111827",
    yellowLight: "#FFFBE6",
  },
  fonts: {
    heading: "Inter, sans-serif",
    body: "Inter, sans-serif",
  },
  textStyles: {
    logo: {
      fontSize: "1.5rem",
      fontWeight: "600",
      color: "#fff",
    },
    nav: {
      fontWeight: "500",
      color: "#fff",
      _hover: {
        textDecoration: "underline",
        color: "rgba(255,255,255,0.7)",
        cursor: "pointer",
      },
    },
    bodyHeading: {
      fontSize: {
        base: "2rem",
        lg: "2.5rem",
      },
      fontWeight: "600",
    },
    articleTitle: {
      fontWeight: 400,
      fontSize: "1.75rem",
      lineHeight: "2rem",
    },
    articleIntro: {
      fontStyle: "normal",
      fontWeight: 100,
      fontSize: {
        base: "1rem",
      },
      lineHeight: {
        base: "2rem",
      },
    },
    articleText: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: {
        base: "1rem",
      },
      lineHeight: {
        base: "1.25rem",
        md: "1.5rem",
      },
    },
    listText: {
      fontSize: "1.25rem",
      fontWeight: "bold",
      lineHeight: {
        base: "1.25rem",
        md: "1.5rem",
      },
    },
    marketingTitle: {
      fontWeight: 600,
      fontSize: { base: "2.5rem", md: "3rem" },
      lineHeight: { base: "3.5rem", md: "4.5rem" },
    },
    titleLandingPage: {
      fontSize: { base: "28px", md: "44px" },
      fontWeight: 600,
      lineHeight: {
        base: "40px",
        md: "54px",
      },
    },
    titleSection: {
      fontSize: { base: "28px", md: "36px" },
      lineHeight: { base: "36px", md: "42px" },
      fontWeight: "700",
    },
    text: {
      fontSize: { base: "16px", sm: "19px" },
      lineHeight: "22px",
      fontWeight: "400",
    },
  },
  zIndices: {
    header: 10,
    toast: 999999,
  },
});

export default theme;
